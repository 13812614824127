import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Connector, useConnect } from 'wagmi';
import { UserDatasource } from '../datasources/UserDatasource';

const Home: NextPage = () => {
  const [{data, error, loading}, connect] = useConnect();
  const [supportedConnectors, setSupportedConnectors] = useState<Connector[]>([]);
  const [isShowWalletSelectModal, setIsShowWalletSelectModal] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setSupportedConnectors(data.connectors.filter(c => c.ready));
    if (!loading && !error) {
      setIsShowWalletSelectModal(false);
    }

  }, [data.connectors, error, loading]);

  return (
    <>
      <Head>
        <title>Renue - Crypto Payment Gateway</title>
        <meta name="description" content="Renue - Crypto Payment Gateway" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="w-full min-h-screen bg-accent bg-gradient-to-r from-primary">
        <div className="flex flex-col justify-center py-24">
          <h1 className="main-text text-white text-center"><i>RENUE</i></h1>
          <p className="sub-text text-white text-center">Renew payment, Increase Revenue</p>
          <div className="flex justify-center">
            <div className="rounded-lg bg-gray-50 p-8 mt-8">
              <Image src="/logos/bsc-logo.png" alt="bsc" width={120} height={32}/>
              <p className="text-gray-300 text-sm my-4">
                RENUE run on Binance Smart Chain network. <br />
                Please make sure your wallet <b>SUPPORTS</b> Binance Smart Chain.
              </p>
              <button
                className="button mt-2"
                onClick={() => setIsShowWalletSelectModal(true)}
              >
                Connect Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="w-fit-content m-auto h-fit-content p-4 pt-4 bg-white border rounded-2xl"
        isOpen={isShowWalletSelectModal}
      >
        <div className="relative">
          <button
            className="close-btn"
            onClick={() => setIsShowWalletSelectModal(false)}
          >
            <div className="close-btn__line">
              <span className="close-btn__line1" />
              <span className="close-btn__line2"/>
            </div>
          </button>
          {
            supportedConnectors.map((connector) => (
              <div
                className={`button my-2`}
                key={connector.id}
                onClick={() => connect(connector)
                  .then(async result => {
                    if (result?.data?.account) {
                      const user = await UserDatasource.getUser(result.data.account);
                      if (user.id) {
                        router.push('/dashboard', undefined, { shallow: true });
                      } else {
                        router.push('/register', undefined, { shallow: true });
                      }
                    }
                })}
              >
                <Image src={`/logos/${connector.name}.png`} alt={connector.name} width={24} height={24} />
                <p className="ml-3">{connector.name}</p>
                {!connector.ready && ' (unsupported)'}
              </div>
            ))
          }
        </div>
      </Modal>
    </>
  );
};

export default Home;
