export default class HttpRequest {
  static async get(url: string) {
    const response = await fetch(
      `${url}`,
      {
        method: 'GET',
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return response.status;
    }
  }

  static async post(url: string, data: object) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const response = await fetch(
      `${url}`,
      {
        method: 'POST',
        headers,
        body: data ? JSON.stringify(data) : null
      }
    );

    if (response.status === 200) {
      return response.json();
    } else {
      return response.status;
    }
  }
}