import HttpRequest from '../utilities/HttpRequest';

export class UserDatasource {
  static async getUser(walletAddress: string) {
    const hostname = process.env.HOST || 'https://renue-api-2qw7s5dpuq-as.a.run.app';
    const response = await HttpRequest.get(`${hostname}/api/merchants?wallet_address=${walletAddress}`);

    if (response.id) {
      localStorage.setItem('user', JSON.stringify(response));
    }

    return response;
  }

  static async register(name: string, walletAddress: string) {
    const hostname = process.env.HOST || 'https://renue-api-2qw7s5dpuq-as.a.run.app';
    const response = await HttpRequest.post(`${hostname}/api/merchants`, {name, walletAddress});

    if (response.id) {
      localStorage.setItem('user', JSON.stringify(response));
    }

    return response;
  }
}
